import { FC } from 'react';
import cx from 'classnames';

import { Typography } from 'src/components-v2/DataDisplay';
import { Link } from 'src/components/Inputs';

import { List } from '../List';
import { Columns as TColumns } from '../types';

import styles from './Columns.module.scss';

type ColumnsProps = { className?: string; items: TColumns['children'] };

export const Columns: FC<ColumnsProps> = ({ className, items }) => {
  const [firstColumn, secondColumn] = items;

  return (
    <div className={cx(styles.Columns, className)}>
      <div className={styles.Column}>
        <Typography className={styles.Title} theme='main-menu-new' variant='h6'>
          {firstColumn.title}
        </Typography>

        <List className={styles.Column__list} items={firstColumn.children} />
      </div>

      <div className={styles.Column}>
        <Typography className={styles.Title} theme='main-menu-new' variant='h6'>
          {secondColumn.title}
        </Typography>

        <ul className={styles.List}>
          {secondColumn.children.map((item, i) => {
            const { href, title } = item;

            return (
              <li
                className={styles.List__item}
                key={`main-menu-column-item-${i}`}
              >
                <Link href={href}>
                  <a className={styles.Link} role='link' tabIndex={0}>
                    <Typography
                      theme='main-menu-new'
                      variant='p'
                      component='span'
                      sx={{
                        mb: 0,
                      }}
                    >
                      {title}
                    </Typography>
                  </a>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
