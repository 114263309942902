import { FC } from 'react';
import cx from 'classnames';

import { Card } from '../Card';
import { isCardWImage, CardWIcon, CardWImage } from '../types';

import styles from './List.module.scss';

type ListProps = {
  className?: string;
  items: Array<CardWIcon | CardWImage>;
};

export const List: FC<ListProps> = ({ className, items }) => {
  const isImagesGrid = items.some((child) => {
    return isCardWImage(child);
  });

  return (
    <ul
      className={cx(
        styles.List,
        { [styles.List_large]: isImagesGrid },
        className,
      )}
    >
      {items.map((item, i) => {
        return (
          <li key={`main-menu-list-item-${i}-${item.href}`}>
            <Card {...item} />
          </li>
        );
      })}
    </ul>
  );
};
