import { FC } from 'react';

import { useDeviceSize } from 'src/hooks';
import { Typography } from 'src/components-v2/DataDisplay';

import { SubMenuData } from '../types';
import { Tabs } from '../Tabs';
import { Columns } from '../Columns';
import { List } from '../List';

import styles from './SubMenu.module.scss';

type SubMenuProps = {
  data: SubMenuData;
  onClick: (id: string) => () => void;
};

export const SubMenu: FC<SubMenuProps> = ({ data, onClick }) => {
  const { type, children } = data;

  const device = useDeviceSize();
  const { isSmall } = device;

  switch (type) {
    case 'list': {
      return <List className={styles.SubMenu__list} items={children} />;
    }

    case 'columns': {
      return <Columns items={children} />;
    }

    case 'tabs': {
      return isSmall ? (
        <ul className={styles.SubMenu__simpleList}>
          {children.map(({ id, title }) => {
            return (
              <li key={`main-menu-tabs-item-${id}`}>
                <button
                  className={styles.Button}
                  type='button'
                  tabIndex={0}
                  onClick={onClick(id)}
                >
                  <Typography
                    theme='main-menu-new'
                    component='span'
                    variant='p'
                    sx={{
                      mb: 0,
                    }}
                  >
                    {title}
                  </Typography>
                </button>
              </li>
            );
          })}
        </ul>
      ) : (
        <Tabs className={styles.SubMenu__tabs} items={children} />
      );
    }

    default: {
      return null;
    }
  }
};
