import { FC } from 'react';
import cx from 'classnames';
import BaseIcon from 'threads5/Icon';

import { Typography } from 'src/components-v2/DataDisplay';
import { Link } from 'src/components-v2/Inputs';

import { isCardWIcon, isCardWImage, CardWIcon, CardWImage } from '../types';
import { ChevronRightBold, Logo, MenuImages } from '../assets';

import styles from './Card.module.scss';

type CardProps = {
  className?: string;
  iconSize?: 18 | 20;
  iconBoxSize?: 32 | 40;
  tabIndex?: number;
} & (CardWIcon | CardWImage);

export const Card: FC<CardProps> = (props) => {
  const {
    className,
    href,
    title,
    description,
    iconSize = 18,
    iconBoxSize = 32,
    tabIndex,
  } = props;

  const Chevron = description ? (
    <ChevronRightBold sx={{ width: 14 }} />
  ) : (
    <BaseIcon name='ChevronRight' size={16} />
  );

  const textPart = (
    <>
      <span className={styles.Card__title}>
        <Typography
          theme='main-menu-new'
          variant='p'
          component='span'
          sx={{ mb: 0 }}
        >
          {title}
        </Typography>

        {Chevron}
      </span>

      {description && (
        <Typography
          className={styles.Card__description}
          theme='main-menu-new'
          variant='p'
          component='span'
        >
          {description}
        </Typography>
      )}
    </>
  );

  if (isCardWImage(props)) {
    const Image = MenuImages[props.image];

    return (
      <Link href={href}>
        <a
          className={cx(
            styles.Card,
            styles.Card_image,
            { [styles.Card_description]: !!description },
            className,
          )}
          tabIndex={tabIndex}
        >
          <span
            className={cx(
              styles.Card__image,
              styles[`Card__image_${props.imagePos}`],
            )}
          >
            <span className={styles.Image}>
              <Image />
            </span>
          </span>

          <span className={styles.Card__body}>{textPart}</span>
        </a>
      </Link>
    );
  }

  if (isCardWIcon(props)) {
    const Icon =
      props.icon === 'PlaidLogo' ? (
        <Logo sx={{ width: `${iconSize}px` }} />
      ) : (
        <BaseIcon
          clipPathId={`${href}-clip-${Date.now()}`}
          data-name={props.icon}
          name={props.icon}
          size={iconSize}
          sx={{ color: 'inherit' }}
        />
      );

    return (
      <Link href={href}>
        <a
          className={cx(
            styles.Card,
            styles.Card_icon,
            { [styles.Card_description]: !!description },
            className,
          )}
          tabIndex={tabIndex}
        >
          <span
            className={cx(
              styles.Card__icon,
              styles[`Card__icon_${iconBoxSize}`],
            )}
          >
            {Icon}
          </span>

          <span className={styles.Card__body}>{textPart}</span>
        </a>
      </Link>
    );
  }

  return (
    <Link href={href}>
      <a className={cx(styles.Card, className)} tabIndex={tabIndex}>
        {textPart}
      </a>
    </Link>
  );
};
