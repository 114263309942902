import { FC, useEffect, useRef, useState, MouseEvent } from 'react';
import { useScroll } from '@react-spring/web';
import cx from 'classnames';

import { useDeviceSize } from 'src/hooks';
import { Container, Row, Column } from 'src/components-v2/Layout';
import { Link } from 'src/components/Inputs';
import { Btn } from 'src/components-v2/Inputs';
import { Locales } from 'src/lib/constants';
import { PlaidLogo } from 'src/components-v2/SVGs';
import { Icon } from 'src/components-v2/DataDisplay';

import { Dialog } from './Dialog';
import { NavList } from './NavList';

import { navData } from './data';
import { DEFAULT_THEME } from './consts';

import styles from './MainNavigationBar.module.scss';

type MainNavigationBarProps = {
  skipLinkAnchor?: string;
};

export const MainNavigationBar: FC<MainNavigationBarProps> = ({
  skipLinkAnchor = '#main-content',
}) => {
  // COMMON
  const { items, ctaTrackingIds } = navData[Locales.EN_US];
  const { isMainNavNew } = useDeviceSize();

  const navRef = useRef<HTMLElement>(null);
  const animatedLayerRef = useRef<HTMLDivElement>(null);

  //FIRST VISIT
  const [isFirstVisit, setIsFirstVisit] = useState(false);

  useEffect(() => {
    setIsFirstVisit(true);
  }, []);

  // SCROLL
  const [scrollDirection, setScrollDirection] = useState<'up' | 'down'>();
  const prevScrollY = useRef(0);

  useScroll({
    onChange: ({ value: { scrollY } }) => {
      if (scrollY > 100) {
        setScrollDirection(scrollY > prevScrollY.current ? 'down' : 'up');
        prevScrollY.current = scrollY;
      }
    },
  });

  // MODAL
  const [isOpen, setOpen] = useState(false);
  const dialogRef = useRef<HTMLDialogElement>(null);

  useEffect(() => {
    if (typeof document === 'undefined') return;

    if (isOpen) {
      document.body.style.overflowY = 'hidden';
      document.body.style.height = 'initial';
    } else {
      document.body.style.overflowY = 'visible';
      document.body.style.height = '100%';
    }
  }, [isOpen]);

  const handleOpenDialog = () => {
    if (dialogRef.current) {
      setOpen(true);
      dialogRef.current.showModal();
    }
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  // SMOOTH SCROLL
  const handleScrollTo = (event: MouseEvent<HTMLButtonElement>) => {
    if (typeof document === 'undefined' || typeof window === 'undefined')
      return;

    const id = event.currentTarget.getAttribute('data-href');
    const tagetElement = document.querySelector(id) as HTMLElement;

    if (!tagetElement) return;

    const top = tagetElement.clientTop;
    const navHeight = navRef.current ? navRef.current.clientHeight : 0;

    window.scrollTo({
      top: top + navHeight,
      behavior: 'smooth',
    });

    // Focus
    const focusableElements = tagetElement.querySelectorAll<HTMLElement>(
      'a[href], button:not([disabled]), input:not([disabled]), select:not([disabled]), textarea:not([disabled]), [tabindex]:not([tabindex="-1"])',
    );

    if (focusableElements.length > 0) {
      focusableElements[0].focus();
    }
  };

  return (
    <nav
      ref={navRef}
      className={cx(styles.Root, styles[`Root_${DEFAULT_THEME}`])}
    >
      <div ref={animatedLayerRef} className={styles.AnimatedLayer} />

      <Container className={styles.Root__containe}>
        <Row>
          <Column xs={24} lgOffset={2} lg={20}>
            <div
              className={cx(styles.Root__bar, {
                [styles.Root__bar_firstVisit]: isFirstVisit,
                [styles.Root__bar_hide]:
                  !isMainNavNew && scrollDirection === 'down',
                [styles.Root__bar_show]:
                  !isMainNavNew && scrollDirection === 'up',
              })}
            >
              <div className={styles.Root__inner}>
                <button
                  type='button'
                  data-href={skipLinkAnchor}
                  className={styles['Root__link-to-content']}
                  tabIndex={0}
                  onClick={handleScrollTo}
                >
                  Skip to main content
                </button>

                {isMainNavNew ? (
                  <>
                    <NavList items={items} animatedLayerRef={animatedLayerRef}>
                      <Link key={'qwer-23'} href='/'>
                        <a
                          className={styles.Logo}
                          aria-label='Go to Plaid homepage'
                          role='link'
                          tabIndex={0}
                        >
                          <PlaidLogo
                            sx={{
                              display: 'block',
                              height: { xs: '24px', sm: '28px' },
                            }}
                          />
                        </a>
                      </Link>
                    </NavList>

                    <div className={styles.Root__toolkit}>
                      <Btn
                        className={cx(styles.Button, styles.Button_secondary)}
                        variant='secondary'
                        href='https://dashboard.plaid.com/signin/'
                        tabIndex={0}
                      >
                        Log in
                      </Btn>

                      <Btn
                        className={cx(styles.Button, styles.Button_primary)}
                        variant='primary'
                        iconName='ChevronRight'
                        iconSize={16}
                        defaultGap='0.6rem'
                        href='https://dashboard.plaid.com/contact'
                        trackingId={ctaTrackingIds.getAPIkeys}
                        tabIndex={0}
                      >
                        Contact sales
                      </Btn>
                    </div>
                  </>
                ) : (
                  <>
                    <Link key={'qwer-56'} href='/'>
                      <a
                        className={styles.Logo}
                        aria-label='Go to Plaid homepage'
                        role='link'
                        tabIndex={0}
                      >
                        <PlaidLogo
                          sx={{
                            display: 'block',
                            height: { xs: '24px', sm: '28px' },
                          }}
                        />
                      </a>
                    </Link>

                    <button
                      type='button'
                      className={styles.Burger}
                      tabIndex={0}
                      aria-label='Main menu'
                      aria-expanded={isOpen}
                      onClick={handleOpenDialog}
                    >
                      <Icon icon='Menu' sx={{ width: 22, height: 22 }} />
                    </button>
                  </>
                )}
              </div>
            </div>
          </Column>
        </Row>
      </Container>

      <Dialog
        className={styles.Root__dialog}
        ref={dialogRef}
        items={items}
        onClose={handleCloseDialog}
        trackingIds={ctaTrackingIds}
      />
    </nav>
  );
};
