import {
  ComponentPropsWithoutRef,
  forwardRef,
  RefObject,
  useEffect,
  useMemo,
  useState,
} from 'react';
import _debounce from 'lodash/debounce';
import cx from 'classnames';

import { Link } from 'src/components/Inputs';
import { PlaidLogo } from 'src/components-v2/SVGs';
import { Typography, Icon } from 'src/components-v2/DataDisplay';

import { SubMenu } from '../SubMenu';
import { isLink, NavDataItems } from '../types';
import { findChildById, findParentById } from '../utils';

import styles from './Dialog.module.scss';

type DialogProps = ComponentPropsWithoutRef<'dialog'> & {
  className?: string;
  items: NavDataItems;
  trackingIds: { getAPIkeys: string };
  onClose?: () => void;
};

export const Dialog = forwardRef(
  (props: DialogProps, ref: RefObject<HTMLDialogElement>) => {
    const { className, items, trackingIds, onClose } = props;

    const [subMenuID, setSubMenuID] = useState<string>(null);

    const subMenuData = useMemo(() => {
      return findChildById(items, subMenuID);
    }, [items, subMenuID]);

    const handleNextMenuLevel = (id: string) => {
      return () => {
        setSubMenuID(id);
      };
    };

    const handlePrevMenuLevel = () => {
      const parent = findParentById(items, subMenuID);
      setSubMenuID(parent ? parent.id : null);
    };

    const handleClose = () => {
      setSubMenuID(null);
      onClose?.();
    };

    const handleResize = _debounce(() => {
      if (!ref.current || !ref.current.open) return;

      handleClose();
      ref.current.close();
    }, 100);

    useEffect(() => {
      window.addEventListener('resize', handleResize);

      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, [handleResize]);

    return (
      <dialog
        className={cx(styles.Dialog, className)}
        id='main-menu-dialog'
        ref={ref}
      >
        <div className={styles.Dialog__inner}>
          <div className={styles.Dialog__head}>
            <form method='dialog'>
              <button
                type='submit'
                className={styles.Close}
                tabIndex={0}
                aria-label='Close main menu modal'
                onClick={handleClose}
              >
                <Icon icon='Close' sx={{ width: 22, height: 24 }} />
              </button>
            </form>

            {subMenuID ? (
              <button
                type='button'
                className={styles.Back}
                tabIndex={0}
                onClick={handlePrevMenuLevel}
              >
                <Icon icon='ChevronLeft' sx={{ width: 18, height: 20 }} />

                <Typography
                  sx={{ mb: 0 }}
                  theme='main-menu-new'
                  variant='p'
                  component='span'
                >
                  Back
                </Typography>
              </button>
            ) : (
              <Link href='/'>
                <a
                  className={styles.Logo}
                  aria-label='Go to Plaid homepage'
                  role='link'
                  tabIndex={0}
                >
                  <PlaidLogo
                    sx={{
                      display: 'block',
                      height: { xs: '24px', sm: '28px' },
                    }}
                  />
                </a>
              </Link>
            )}
          </div>

          <div className={styles.Dialog__body}>
            {subMenuData ? (
              <SubMenu data={subMenuData} onClick={handleNextMenuLevel} />
            ) : (
              <ul className={styles.List}>
                {items.map((item, i) => {
                  const key = `menu-item-${i}`;

                  if (!isLink(item)) {
                    const { id, title } = item;

                    return (
                      <li key={key}>
                        <button
                          type='button'
                          className={styles.Button}
                          tabIndex={0}
                          onClick={handleNextMenuLevel(id)}
                        >
                          <Typography
                            sx={{ mb: 0 }}
                            theme='main-menu-new'
                            variant='p'
                            component='span'
                          >
                            {title}
                          </Typography>
                        </button>
                      </li>
                    );
                  }

                  const { href, title, trackingId } = item;

                  return (
                    <li key={key}>
                      <Link href={href} trackingId={trackingId}>
                        <a className={styles.Link}>
                          <Typography
                            sx={{ mb: 0 }}
                            theme='main-menu-new'
                            variant='p'
                            component='span'
                          >
                            {title}
                          </Typography>
                        </a>
                      </Link>
                    </li>
                  );
                })}

                <hr className={styles.Line} />

                <li>
                  <Link href='https://dashboard.plaid.com/signin/'>
                    <a className={styles.Link}>
                      <Typography
                        sx={{ mb: 0 }}
                        theme='main-menu-new'
                        variant='p'
                        component='span'
                      >
                        Log in
                      </Typography>
                    </a>
                  </Link>
                </li>

                <li>
                  <Link
                    href='https://dashboard.plaid.com/contact'
                    trackingId={trackingIds?.getAPIkeys}
                  >
                    <a className={styles.Link}>
                      <Typography
                        sx={{ mb: 0 }}
                        theme='main-menu-new'
                        variant='p'
                        component='span'
                      >
                        Contact sales
                      </Typography>
                    </a>
                  </Link>
                </li>
              </ul>
            )}
          </div>
        </div>
      </dialog>
    );
  },
);
